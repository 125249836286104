import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../components/Logo';

import styles from './Complete.module.css';

const Complete = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const isCancelled = Boolean(query.get('canceled'));

  useEffect(() => {
    if (isCancelled) {
      navigate('/details');
    }
  }, [navigate, isCancelled]);

  return (
    <div className={styles.wrapper}>
      <main className={styles.content}>
        <Logo />
        {!isCancelled && (
          <div className={styles.message}>
            <Typography variant="h6" gutterBottom component="div"><b>You're covered! &#128526;</b></Typography>
            <Typography variant="h6" gutterBottom component="div">If your EV ever runs flat then give us a call on <a href="tel:01928249460">01928 249 460</a></Typography>
          </div>
        )}
      </main>
    </div>
  );
}

export default Complete;
