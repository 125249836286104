import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";

import Logo from '../../components/Logo';

import styles from './Home.module.css';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector('body').style.background = '#33cc01';

    return () => document.querySelector('body').style.background = null;
  }, []);

  return (
    <div className={styles.wrapper}>
      <main className={styles.content}>
        <Logo white />
        <ul className={styles.list}>
          <li className={styles.listItem}><Typography variant="h6" gutterBottom component="div">&#10071; Traditional roadside recovery <b>won't work</b> for flat EV batteries</Typography></li>
          <li className={styles.listItem}><Typography variant="h6" gutterBottom component="div">We'll re-charge your EV at the roadside &#9889;</Typography></li>
          <li className={styles.listItem}><Typography variant="h6" gutterBottom component="div">&#9995; Say goodbye to range anxiety for just <b>&pound;24.99</b> a month</Typography></li>
          <li className={styles.listItem}><Typography variant="h6" gutterBottom component="div">You'll be free to enjoy your EV worry free &#128526; &#128664;</Typography></li>
        </ul>
      </main>
      <footer className={styles.footer}>
        <Button variant="contained" size="large" onClick={() => navigate('/details')}>Get Your Cover Today</Button>
      </footer>
    </div>
  );
}

export default Home;
