import { BrowserRouter, Route, Routes } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';

import Home from './pages/Home';
import Details from './pages/Details';
import Complete from './pages/Complete';

const App = () => (
  <BrowserRouter>
    <CssBaseline />
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/details" element={<Details />} />
      <Route exact path="/complete" element={<Complete />} />
    </Routes>
  </BrowserRouter>
);

export default App;
